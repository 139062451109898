<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: '16px',}">
    <a-row :gutter="16">
      <a-col :span="5">
        <website-list @change="handleWebsiteChange" />
      </a-col>
      <a-col :span="19">
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">{{ moduleName }}</h5>
          </a-col>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
            <a-button type="primary" icon="plus" @click="toEdit({})">创建{{ moduleName }}</a-button>
          </a-col>
        </a-row>
        <a-table :loading="tableLoading" :columns="finalColumns" :data-source="datasource"
                 :pagination="false"
                 :rowKey="record => record.id">
          <template slot="icon" slot-scope="text">
            <a-avatar shape="square" :src="text" />
          </template>
          <template slot="time" slot-scope="text">
            <time-tag :time="text" />
          </template>
          <template slot="action" slot-scope="row">
            <a-button type="link" size="small" :data-id="row.key" icon="eye" @click="toDetail(row)">
              查看
            </a-button>
            <a-button type="link" size="small" :data-id="row.key" icon="plus" @click="toEdit(row, true)">
              新增子{{ moduleName }}
            </a-button>
            <a-button type="link" size="small" :data-id="row.key" icon="form" @click="toEdit(row)">
              编辑
            </a-button>
            <a-button type="link" size="small" class="danger-button" :data-id="row.key" icon="delete" @click="toDelete(row)">
              删除
            </a-button>
          </template>
        </a-table>
      </a-col>
    </a-row>
    <ActDrawer ref="ActDrawerRef" @success="loadData" />
  </a-card>
</template>

<script>
import {tree, remove} from "@/api/service/column";
import ActDrawer from "@/views/column/ActDrawer.vue";
import {tableMixin} from "@/mixin/table";
import {columns} from "@/views/column/options";
import TimeTag from "@/components/table/time-tag.vue";
import WebsiteList from "@/components/table/website-list.vue";
import {isEmpty} from "lodash";

export default {
  components: {TimeTag, ActDrawer, WebsiteList},
  mixins: [tableMixin],
  data() {
    return {
      moduleName: '栏目',
      requestType: 'tree',
      columns,
      immediate: false,
    }
  },
  methods: {
    tree,
    remove,
    toEdit(record, addChild = false) {
      this.$refs.ActDrawerRef.open({
        edit: true,
        record,
        addChild,
        websiteId: this.treeParams.websiteId,
      })
    },
    toDetail(record) {
      this.$refs.ActDrawerRef.open({
        edit: false,
        record
      })
    },
    handleWebsiteChange(website) {
      this.treeParams = {
        websiteId: website.id
      }
      this.loadData()
    },
    toDelete(record) {
      if(isEmpty(record.children)) {
        this.doDelete(record)
      } else {
        this.$warning({
          title: '无法删除',
          content: `栏目 ${record.name} 下有子栏目，请先删除子栏目`,
        });
      }
    },
  },
}

</script>

<style lang="scss">
.website-container{
  min-height: 300px;
}
.website-item{
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  //box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #f0f5ff;
  margin-top: 12px;
  &:hover,
  &.activity{
    background-color: #d6e4ff;
  }
  .website-item-content{
    padding-left: 12px;
  }
}
</style>
